/* lista tipo JSON, 
    en esta lista se encuentran los datos de los profesores  */
export const profesores_data = [
    {
        ID: 1,
        Foto: require("../images/Equipo_de_trabajo/Juan_Sossa.jpg"),/*poner foto en la ruta, y cambiar el nombre alcorespondiente */
        Nombre: "Dr. Juan Humberto Sossa Azuela, SNI Emérito",
        Correo: "humbertosossa@gmail.com",
        Link: "",/*poner link directo de la pagina personal entre comillas */
    },
    {
        ID: 2,
        Foto: require("../images/Equipo_de_trabajo/Elsa.jpg"),
        Nombre: "Dra. Elsa Rubio Espino, SNI I",
        Correo: "erubio@cic.ipn.mx",
        Link: "",
    },
    {
        ID: 3,
        Foto: require("../images/Equipo_de_trabajo/Erik.jpg"),
        Nombre: "Dr. Erik Zamora, SNI I",
        Correo: "ezamora1981@gmail.com",
        Link: "",
    },
    {
        ID: 4,
        Foto: require("../images/Equipo_de_trabajo/Yalja.jpg"),
        Nombre: "Dr. Jesús Yaljá Montiel Pérez",
        Correo: "yalja@ipn.mx",
        Link: "https://proyectosrym.cic.ipn.mx/yalja/",
    },
    {
        ID: 5,
        Foto: require("../images/Equipo_de_trabajo/Ponciano.jpg"),
        Nombre: "Dr. Ponciano Jorge Escamilla Ambrosio, SNI II",
        Correo: "pescamillaa@ipn.mx",
        Link: "",
    },
]