import React from 'react';
import plecaSEp from '../images/pleca-educacion.svg';
import logoIPN from '../images/IPN-logo.png';
import styles from '../css/headerIPN.css'; // Asegúrate de que el archivo CSS esté importado correctamente

const Header = () => {
    return (
        <header className="header">
           <div className='header-top'>
                <nav>
                            <a href="https://www.ipn.mx/directorio-telefonico.html">DIRECTORIO</a> |
  
                            <a href="https://www.ipn.mx/correo-electronico.html">CORREO ELECTRÓNICO</a> |
  
                            <a href="https://www.ipn.mx/calendario-academico.html">CALENDARIO</a> |

                             <a href="https://www.ipn.mx/transparencia/">TRANSPARENCIA</a> |

                            <a href="https://www.ipn.mx/proteccion-datos-personales/">PROTECCIÓN DE DATOS</a>|
                </nav>
                </div>
            <div className="header-main">
              
                <div className="logos">
                    <a href="https://www.gob.mx/sep" className="logo-link">
                        <img src={plecaSEp} alt="Logo de la Secretaría de Educación Pública" className="logo" />
                    </a>
                    <a href="https://www.ipn.mx" className="logo-link">
                        <img src={logoIPN} alt="Logo del Instituto Politécnico Nacional" className="logo" />
                    </a>
                </div>
                <div className="header-text">
                    <p>Instituto Politécnico Nacional</p>
                    <p>"La Técnica al Servicio de la Patria"</p>
                </div>
            </div>
            <div className="header-container">
            <div className="header-bottom">
                <p>
                    Con fundamento en los artículos 41 Base III, Apartado C, párrafo segundo, y 134 párrafos primero y séptimo de la Constitución Política de los Estados Unidos Mexicanos; 209 numeral 1; 442 numeral 1 inciso f) y 449 de la Ley General de Instituciones y Procedimientos Electorales; 5 y 11 de la Ley General en Materia de Delitos Electorales; y para hacer valer los principios de imparcialidad y neutralidad en los procesos electorales federales, estatales y municipales de este año, hasta el 2 de junio la página de internet, se mantendrá en reserva, salvo las excepciones previstas en el marco normativo.
                </p>
            </div>
                <div className="header-icons">
                
                <svg className="icon" aria-hidden="true" data-prefix="fab" data-icon="accessible-icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                    <path fill="currentColor" d="M423.9 255.8L411 413.1c-3.3 40.7-63.9 35.1-60.6-4.9l10-122.5-41.1 2.3c10.1 20.7 15.8 43.9 15.8 68.5 0 41.2-16.1 78.7-42.3 106.5l-39.3-39.3c57.9-63.7 13.1-167.2-74-167.2-25.9 0-49.5 9.9-67.2 26L73 243.2c22-20.7 50.1-35.1 81.4-40.2l75.3-85.7-42.6-24.8-51.6 46c-30 26.8-70.6-18.5-40.5-45.4l68-60.7c9.8-8.8 24.1-10.2 35.5-3.6 0 0 139.3 80.9 139.5 81.1 16.2 10.1 20.7 36 6.1 52.6L285.7 229l106.1-5.9c18.5-1.1 33.6 14.4 32.1 32.7zm-64.9-154c28.1 0 50.9-22.8 50.9-50.9C409.9 22.8 387.1 0 359 0c-28.1 0-50.9 22.8-50.9 50.9 0 28.1 22.8 50.9 50.9 50.9zM179.6 456.5c-80.6 0-127.4-90.6-82.7-156.1l-39.7-39.7C36.4 287 24 320.3 24 356.4c0 130.7 150.7 201.4 251.4 122.5l-39.7-39.7c-16 10.9-35.3 17.3-56.1 17.3z"></path>
                </svg>
         
          
                <svg className="icon" aria-hidden="true" data-prefix="fas" data-icon="adjust" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                    <path fill="currentColor" d="M8 256c0 136.966 111.033 248 248 248s248-111.034 248-248S392.966 8 256 8 8 119.033 8 256zm248 184V72c101.705 0 184 82.311 184 184 0 101.705-82.311 184-184 184z"></path>
                </svg>
        
            <a href="#" className="text-icon">A+</a>
            <a href="#" className="text-icon">A-</a>
            <a href="#" className="text-icon">A</a>
        </div>
            </div>

           
        </header>
    );
};

export default Header;
