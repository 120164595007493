import logoLab from "../images/logoLab.png";
import styles from "../css/HomePage.module.css";
import { useState } from "react";
import ListaGrupoTrabajo from "../components/ListGrupoTrabajo";
import {ListLinksMenu} from "../templates/NavBar";

export const HomePage = () => {
  const [isMenuActive, setMenuActive] = useState(true);

  const handleMenu = () => {
    setMenuActive(isMenuActive ? false : true);
  }

  return (
    <>
    
     
        <nav className={styles.navbar}>
          <div className={styles.barsMenu} onClick={handleMenu}>
            <span className={isMenuActive ? styles.activeline1Bars : undefined}></span>
            <span className={isMenuActive ? styles.activeline2Bars : undefined}></span>
            <span className={isMenuActive ? styles.activeline3Bars : undefined}></span>
          </div>
          {isMenuActive && <ListLinksMenu isMenuActive={isMenuActive} styles={styles}/>}
        </nav>
        <header className={styles.header}>
        <div className={styles.headerContenedor}>
          <div>
            <img
              className={styles.headerSombra}
              style={{ marginRight: 20 }}
              height={150}
              src={logoLab}
              alt='logo ipn'
            />
          </div>
          <div className={styles.headerTexto}>
            <h2>Instituto Politécnico Nacional</h2>
            <p>Centro de Investigación en Computación</p>
            <p>Laboratorio de Robótica y Mecatrónica</p>
          </div>
        </div>
      </header>
      <div className={styles.contenedorHomepage}>
        <div className={styles.homepageObjetivos}>
          <h3 className={styles.homepageSubtitulo}>Objetivos del Laboratorio de Robótica y Mecatrónica</h3>
          <ul className={styles.homepageListUl}>
            <li>Generar nuevo conocimiento.</li>
            <li>Aplicar este conocimiento en la solución de problemas teóricos fundamentales.</li>
            <li>Transformar estos conocimientos en soluciones a problemas planteados por los sectores público y privado.</li>
          </ul>
        </div>
        <h3 className={styles.homepageSubtitulo}>Líneas de Investigación</h3>
        <div className={styles.homepageLineas}>
          <div>
            <h4 className={styles.lineasSubtitulo}>Líneas</h4>
            <ul className={styles.homepageListUl}>
              <li>Aprendizaje para máquinas</li>
              <li>Control clásico</li>
              <li>Control difuso</li>
              <li>Cómputo evolutivo</li>
              <li>Cómputo cuántico</li>
              <li>Modelado y control de robots</li>
              <li>Reconocimiento de patrones</li>
              <li>Redes neuronales y memorias asociativas</li>
              <li>Tratamiento y análisis digital de imágenes</li>
              <li>Sensores e instrumentación</li>
              <li>Óptica de adquisición de imagen</li>
            </ul>
          </div>
          <div>
            <h4 className={styles.lineasSubtitulo}>Aplicación</h4>
            <ul className={styles.homepageListUl}>
              <li>Robots móviles</li>
              <li>Drones</li>
              <li>Robots de servicio</li>
              <li>Drones</li>
              <li>Sensores</li>
              <li>Smart cities</li>
            </ul>
          </div>
        </div>
        <h3 className={styles.homepageSubtitulo}>Grupo de trabajo</h3>
        <ListaGrupoTrabajo />
      </div>
    </>
  );
}