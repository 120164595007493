import logoLab from "../images/logoLab.png";
import styles from "../css/HomePage.module.css";
import BackToTop from "react-back-to-top-button";
import { AiOutlineArrowUp } from "react-icons/ai";
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import stylespdf from "../css/PDF.module.css";

/*
esta es la vista para visualizar pdf
actualmente no esta en uso, se realiza de forma directa
algunas funciones no funcionan de la mimaforma cuando se despliega el build
*/
export const PDF = () => {
  const { PDF } = useParams()
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () =>
    setPageNumber(
      pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
    );
  return (
    <>

      <div className={stylespdf.divpdf}>
        <nav>
          <button className={stylespdf.botonnaranja} onClick={goToPrevPage}>Anterior</button>
          <button className={stylespdf.botonnaranja} onClick={goToNextPage}>Siguiente</button>
          <p>
            Pagina {pageNumber} de {numPages}
          </p>
        </nav>
        <div className={stylespdf.divdocument}>
          <Document
            file={require(`../pdf/exposiciones_de_seminario/${PDF}.pdf`)}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page size="A4" pageNumber={pageNumber} renderTextLayer={false} >

            </Page>
          </Document>
        </div>
      </div>
      <BackToTop
        showOnScrollUp
        showAt={100}
        speed={100}
        easing="easeOutSine"
      >
        <span className={styles.BackToTop}><AiOutlineArrowUp style={{ color: '#fff', fontSize: 30 }} /></span>
      </BackToTop>
      <footer className={styles.footer}>
        <div>
          <img
            src={logoLab}
            alt='logo cic'
          />
        </div>
        <div>
          <p>Instituto Politécnico Nacional</p>
          <p>Centro de Investigación en Computación</p>
          <p>Laboratorio de Robótica y Mecatrónica</p>
        </div>
        <div>
          <p><strong>Dirección</strong></p>
          <p>Av. Juan de Dios Bátiz, esq. Miguel Othón de Mendizábal,</p>
          <p>Col.Nueva Industrial Vallejo, Alcaldía Gustavo A. Madero,<br></br> C.P. 07738, CDMX</p>
        </div>
      </footer>
    </>
  );

}