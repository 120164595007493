import { useState } from "react";
import { NavLink } from "../templates/NavLink";
import logoIPN from "../images/IPN-logo.png";
import logoCIC from "../images/cic-logo.png";
import logo from "../images/logolabClaro.png";

export const ListLinksMenu = (props) => {
  const styles = props.styles
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownEventos, setDropdownEventos] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleDropdownEventos = () => {
    setDropdownEventos(!dropdownEventos);
  };

  const logosPrincipal = () => {
    return (

      <div className={styles.logos}>
        {/*
        <a href="https://www.ipn.mx">
          <img src={logoIPN} alt="logo ipn" />
        </a>
        */
  }
        <a href="https://www.cic.ipn.mx/">
          <img src={logoCIC} alt="logo cic" />
        </a>
      </div>
    );
  };

  const logosSubprincipal = () => {
    return (
      <p className={styles.logoCenter}>
        <img src={logo} alt="logo lab" height="70px" />
      </p>
    )
  }

  let logos;
  if (props.subprincipal === true) {
    logos = logosSubprincipal()
  } else {
    logos = logosPrincipal()
  }

  const componente = (
    <>
      <ul className={styles.listLinks}>
        <li>
          <NavLink to="/">Inicio</NavLink>
        </li>
        <li onClick={toggleDropdown}>
          <a>Alumnos</a>
          {dropdownVisible && (
            <ul className={styles.nestListLinks}>
              <li>
                <NavLink to="/Alumnos23B">
                  Semestre 23B, agosto-diciembre 2023
                </NavLink>
              </li>
              <li>
                <NavLink to="/Alumnos23A">
                  Semestre 23A, enero-julio 2023
                </NavLink>
              </li>
            </ul>
          )}
        </li>
        <li>
          <NavLink to="/proyectos">Proyectos</NavLink>
        </li>
        <li>
          <NavLink to="/tesis">Propuestas de Tesis</NavLink>
        </li>
        <li>
          <NavLink to="/estancias">Estancias</NavLink>
        </li>
        <li onClick={toggleDropdownEventos}>
          <a>Eventos</a>
          {dropdownEventos && (
            <ul className={styles.nestListLinks}>
              <li> <NavLink to="/programa-delfin" className={styles.link}>Estancia Programa Delfín</NavLink> </li>
              <li> <a href="https://proyectosrym.cic.ipn.mx/qcoloquio/" className={styles.link}>3er Coloquio de cómputo cuántico</a> </li>
            </ul>
          )}
        </li>
        <li>
          <NavLink to="/galeria">Galería</NavLink>
        </li>
      </ul>
    </>
  )

  return (
    <>
      {logos}
      {componente}
    </>
  );
};
