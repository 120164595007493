import BackToTop from "react-back-to-top-button";
import { AiOutlineArrowUp } from "react-icons/ai";
import styles from "../css/HomePage.module.css";
import logoSEP from "../images/educacion2.png";

export const FooterInst = () => {
  return (
    <>
      <BackToTop showOnScrollUp showAt={100} speed={100} easing="easeOutSine">
        <span className={styles.BackToTop}>
          <AiOutlineArrowUp style={{ color: "#fff", fontSize: 30 }} />
        </span>
      </BackToTop>
      <footer className={styles.footerIPN}>
       
        <div>
            <div className={styles.divFooter}>
            <p>
   <strong>INSTITUTO POLITÉCNICO NACIONAL</strong>
     
       </p>
       </div>
       <div className={styles.divFooter}>
       <p>
     
            
D.R. Instituto Politécnico Nacional (IPN). Av. Luis Enrique Erro S/N, Unidad Profesional Adolfo López Mateos, Zacatenco, Alcaldía Gustavo A. Madero, C.P. 07738, Ciudad de México. Conmutador: 55 57 29 60 00 / 55 57 29 63 00.
            </p>
            </div>
            <div className={styles.divFooter}>
            <p>
          
Esta página es una obra intelectual protegida por la Ley Federal del Derecho de Autor, puede ser reproducida con fines no lucrativos, siempre y cuando no se mutile, se cite la fuente completa y su dirección electrónica; su uso para otros fines, requiere autorización previa y por escrito de la Dirección General del Instituto.

            </p>
            </div>
        <div className={styles.footerImageIPN}>
          <img src={logoSEP} alt="logo sep" />
        </div>
        </div>
      
      </footer>
    </>
  );
};
