import styles2 from "../css/Tesis.module.css";
import { propuestas_proyectos } from "../data/propuestas_proyectos";

export const Proyectos = () => {
  return (
    <>
      <div>
        <h1 style={{ textAlign: "center", margin: 30 }}>Proyectos</h1>
        <div className={styles2.containerWrap} >
          <table className={styles2.tesisTable} style={{ width: '95%' }}>
            <tr>
              <th>Tipo Proyecto</th>
              <th>Título </th>
              <th>Resumen</th>
              <th>Responsable</th>
            </tr>

            {propuestas_proyectos.map(({ id, Tipo, Titulo, Resumen, Responsable }) => (
              <tr>
                <td>
                  <b>{Tipo}</b>
                </td>
                <td>
                  {Titulo}
                </td>
                <td className={styles2.proyectoResumen} >
                  {Resumen}
                </td>
                <td className={styles2.proyectoResponsables}>
                  {Responsable.map((name) => (
                    <li>{name}</li>
                  ))}
                  <ul>  </ul>
                </td>
              </tr>

            ))}
          </table>
        </div>
        {propuestas_proyectos.length === 0 && (
          <h3 style={{ textAlign: "center", margin: 30 }}>Aun no hay propuestas, pronto añadiremos nuevas</h3>
        )}
      </div>
    </>
  );
}