import styles from "../css/HomePage.module.css";
import { estancias_data } from "../data/estancias_data";

export const Estancias = () => {
  return (
    <>
      <h1 style={{ textAlign: "center", margin: 30 }}>
        Estancias de los alumnos del Centro de Investigación en Computación
      </h1>
      <div>
        <p className={styles.eventospageParrafo}>
          Como parte esencial de la vida académica de nuestros alumnos de
          posgrado en el Laboratorio, se promueve que lleven a cabo estancias de
          investigación en instituciones externas, tanto nacionales como
          internacionales. Estas estancias tienen como principal objetivo
          complementar y enriquecer sus conocimientos, metodologías y
          experimentación relacionados con sus trabajos de investigación de
          tesis.
        </p>
        <table className={styles.tablaEstancias}>
          <tbody>
            <tr>
              <th>Alumno</th>
              <th>Estancia</th>
              <th className={styles.descRow}>Actividades</th>
            </tr>
            {estancias_data.map((estancia) => {
              return (
                <tr>
                  <td> <img src={estancia.Foto} className={styles.estanciasImg}/> <p>{estancia.Nombre}</p> <p style={{fontSize:15,marginTop:5}}><i>{estancia.Alt}</i></p> </td>
                  <td>{estancia.Estancia}<br/><br/><br/>{estancia.Periodo}</td>
                  <td>{estancia.Actividades}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};
