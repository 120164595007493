import styles2 from "../css/Tesis.module.css";
import { propuestas_tesis } from "../data/propuestas_tesis";

export const Tesis = () => {
  return (
    <>
      <div>
        <h1 style={{ textAlign: "center", margin: 30 }}>Propuestas de Tesis</h1>
        <div className={styles2.containerWrap}>
          <table className={styles2.tesisTable}>
            <tr>
              <th>Responsable</th>
              <th>Propuestas de tesis</th>
            </tr>
            {propuestas_tesis.filter(tesis => tesis.Tesis.length > 0).map(({ id, Nombre, Correo, Tesis }) => (
              <tr>
                <td>
                  <b>{Nombre}, {Correo}</b>
                </td>
                <td className={styles2.infoTesis}>
                  {Tesis.map(({ Nivel, Tema, Descripcion }) => (
                    <>
                      <p><b>Nivel:</b> {Nivel}</p>
                      <p><b>Tema:</b> {Tema}</p>
                      <p><b>Descripcion:</b> {Descripcion}</p>
                      <hr style={{margin: 6}}/>
                    </>
                  ))}
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </>
  );
};
